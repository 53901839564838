import React, { useEffect, useState } from "react";
import {PDFViewer, Page, Text, View, Document, StyleSheet, Font,Image,pdf } from '@react-pdf/renderer';
import timesNewRomanFont from './times.ttf'; 
import  timesNewRomanFontbold from './bold.ttf'; 
import emailjs from 'emailjs-com'; // Import the emailjs librar
import { getStorage, ref, getDownloadURL,   uploadBytes } from 'firebase/storage';
import { getFirestore, collection, getDocs,addDoc} from 'firebase/firestore/lite';
import { initializeApp } from 'firebase/app';

import backgroundImage from './sam-logo.png';
Font.register({ family: 'Times New Roman', src: timesNewRomanFont });
Font.register({ family: 'Bold', src: timesNewRomanFontbold });

function App() {
  const formatDate = (inputDate) => {
    const dateParts = inputDate.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    return `${day}/${month}/${year}`;
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const firebaseConfig = {
    apiKey: "AIzaSyCGMDhQP-EAnFmlR9r1hFeZB1S4BFpc3rQ",
    authDomain: "sam-hyper-business-682ea.firebaseapp.com",
    projectId: "sam-hyper-business-682ea",
    storageBucket: "sam-hyper-business-682ea.appspot.com",
    messagingSenderId: "707629335149",
    appId: "1:707629335149:web:1edbe7ab6c476cee02f564",
    measurementId: "G-WLYSXR186X"
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const storage = getStorage(app);
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [authenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [mail, setMail] = useState("");

    const [basicPay, setBasicPay] = useState();
    const [pt, setPT] = useState();
    const [hra, setHRA] = useState();
    const [pf, setPF] = useState();
    const [sa, setSA] = useState();
    const [esi, setESI] = useState();
    const [conveyance, setConveyance] = useState();
    const [bonus, setBonus] = useState();

    const [date,setDate] = useState("");
    const [startDate,setStartDate] = useState("");
    const [name,setName] = useState("");
    const [supervisor,setSupervisor] = useState("");
    const [supervisordesig,setsupervisordesig] = useState("");

    const [mrorms,setmrorms] = useState("");
    const [city,setCity] = useState("");
    const [addressline1,setAddressLine1] = useState("");
    const [addressline2,setAddressLine2] = useState("");
    const [state,setstate] = useState("TAMILNADU");
    const [pincode,setpincode] = useState("");
    const [designation,setDesignation] = useState("");
    const [companyName,setCompanyName] = useState("SAM HYPER BUSINESS P.V.T L.T.D");


  const handleSubmit = (event) => {
    event.preventDefault();

    // Replace this with your actual authentication logic
    const validUsername = "SamHyperBusiness";
    const validPassword = "Sam@0987";

    if (username === validUsername && password === validPassword) {
      setAuthenticated(true);
      // Store the authentication status in localStorage
      localStorage.setItem("authenticated", "true");
    } else {
      alert("Invalid username or password.");
    }
  };

  const handleLogout = () => {
    setAuthenticated(false);
    // Remove the authentication status from localStorage
    localStorage.removeItem("authenticated");
    // Optionally, you can clear the username and password from state as well
    setUsername("");
    setPassword("");
  };

  // Check if the user is already authenticated on initial load
  useEffect(() => {
    const isUserAuthenticated = localStorage.getItem("authenticated");
    if (isUserAuthenticated === "true") {
      setAuthenticated(true);
    }
  }, []);




  const MyPDF = () => (
    <Document>

      {/* page1 */}

      <Page size="A4" style={styles.page }>
      {/* <View style={styles.watermarkContainer}>
      <Image src={backgroundImage} style={styles.watermark} />
      </View>

        <View style={styles.logoContainer}>
          <Image src={backgroundImage} style={styles.logo}></Image>
        </View>

        <View style={styles.footerContainer}> 
        <Text style={styles.boldtext}>SAM HYPER BUSINESS PVT LTD</Text> 
        <Text > <Text style={styles.boldtext} >Reg. Office: </Text>No.76/G1, Ground Floor, Shivalaya Apartments, Rukmani Devi Street</Text> 
        <Text >Majestic Colony, Valsaravakkam, Chennai - 600087.</Text> 
        <Text > <Text style={styles.boldtext}>Branch Office:</Text> No. 22, 1st Floor, Chennai Green City, Thiruverkadu Main Road, Paruthipattu, Chennai - 600071.</Text> 
        <Text ><Text style={styles.boldtext}>Contact:</Text> +91 44 3574 3419 | +91 44 4775 7459 | Email: samhyperbusiness.com</Text> 
        </View> */}
       <View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>


        {/* <View style={styles.dividerContainer}>
        <View style={styles.divider}></View>
        </View>
        */}
        <View style={styles.bigSpace}></View>
       
       <View style={[styles.leftSpace,styles.rightText]}> <Text> Date: {formatDate(date)}</Text> </View>
       <View style={styles.bigSpace}></View>

       <View > <Text style={styles.redText}>{name},</Text> </View>
<View style={styles.space}></View>

       <View > <Text style={styles.redText} >{addressline1},</Text> </View>
<View style={styles.space}></View>

       <View > <Text style={styles.redText} >{addressline2},</Text> </View>
<View style={styles.space}></View>

       <View > <Text style={styles.redText} >{state}, {city} {pincode}.</Text> </View>
<View style={styles.bigSpace}></View>
<View style={styles.space}></View>

       <View style={styles.centerText}><Text><Text style={styles.boldtext}>Subject: </Text> Appointment for post of <Text style={styles.redText}>{designation}</Text></Text></View>
       <View style={styles.bigSpace}></View>
<View style={styles.space}></View>

    
        <View><Text > Dear <Text style={styles.redText}> {mrorms} {name}</Text>,
 </Text></View>
<View style={styles.space}></View>
<View style={styles.space}></View>
 
<View><Text>We are pleased to offer you, the position of <Text style={styles.redText}>{designation} </Text>with {companyName} on the
following terms and conditions:</Text></View>
<View style={styles.leftSpace}>

<View style={styles.bigSpace}></View>

  <Text style={styles.boldtext}> 1. Commencement of employment </Text>
  
<View style={styles.space}></View>

  <Text style={styles.leftSpace}>Your employment will be effective, as of <Text style={styles.redText}>{formatDate(startDate)}</Text>.</Text>
  <View style={styles.bigSpace}></View>

  <Text style={styles.boldtext}> 2. Job title 
 </Text>
<View style={styles.space}></View>

 <Text style={styles.leftSpace}>
  
 Your job title will be <Text style={styles.redText}>{designation}</Text>, and you will report to Supervisor.
 </Text>
 <View style={styles.bigSpace}></View>
 <Text style={styles.boldtext}> 3. Salary 
 </Text>
 <View style={styles.space}></View>

 <Text style={styles.leftSpace}>
  
 Your salary and other benefits will be as set out in Schedule 1. </Text>


<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}> 4. Place of posting 
 </Text>
 <View style={styles.space}></View>

 <Text style={styles.leftSpace}>
 You will be posted at Chennai Tamilnadu. You may however be required to work at any place of business
which the Company has, or may later acquire.</Text>

<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}> 5. Hours of Work 
 </Text>
 <View style={styles.space}></View>
 <Text style={styles.leftSpace}>
 The standard workdays encompass weekdays. 
 You're expected to dedicate the time needed 
 to fulfill your responsibilities to the Company.
  The regular work hours span from morning to evening, 
  and you're required to put in a minimum number of hours 
  hours per week, with the potential for extra hours as dictated by your role demands.
 </Text>

</View>
      </Page>




      {/* page 2 */}
      
      <Page size="A4" style={styles.page }>
      {/* <View style={styles.watermarkContainer}>
      <Image src={backgroundImage} style={styles.watermark} />
      </View>

        <View style={styles.logoContainer}>
          <Image src={backgroundImage} style={styles.logo}></Image>
        </View>

        <View style={styles.footerContainer}> 
        <Text style={styles.boldtext}>SAM HYPER BUSINESS PVT LTD</Text> 
        <Text > <Text style={styles.boldtext} >Reg. Office: </Text>No.76/G1, Ground Floor, Shivalaya Apartments, Rukmani Devi Street</Text> 
        <Text >Majestic Colony, Valsaravakkam, Chennai - 600087.</Text> 
        <Text > <Text style={styles.boldtext}>Branch Office:</Text> No. 22, 1st Floor, Chennai Green City, Thiruverkadu Main Road, Paruthipattu, Chennai - 600071.</Text> 
        <Text ><Text style={styles.boldtext}>Contact:</Text> +91 44 3574 3419 | +91 44 4775 7459 | Email: samhyperbusiness.com</Text> 
        </View> */}

<View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
        {/* <View style={styles.dividerContainer}>
        <View style={styles.divider}></View>
        </View> */}
        
        <View style={styles.bigSpace}></View>

<View style={styles.leftSpace}>
<Text style={styles.boldtext}>6. Leave/Holidays </Text> 
<View style={styles.space}></View>

<Text style={styles.leftSpace}>

a.   You are entitled to casual leave  days. 
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
b.   You are entitled to working days of paid sick leave.
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
c.   The Company shall notify a list of declared holidays in the beginning of each year.
</Text>


<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>7. Nature of duties </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>You will perform to the best of your ability all the duties as are inherent in your post and such
additional duties as the company may call upon you to perform, from time to time. 
</Text>


<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>8. Company property </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>You will always maintain in good condition Company property, which may be entrusted to you for
official use during the course of your employment and shall return all such property to the Company
prior to relinquishment of your charge, failing which the cost of the same will be recovered from
you by the Company.
</Text>

<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>9. Borrowing/accepting gifts </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>You will not borrow or accept any money, gift, reward or compensation for your personal gains
from or otherwise place yourself under pecuniary obligation to any person/client with whom you
may be having official dealings. 
</Text>

<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>10. Termination  </Text> 
<View style={styles.space}></View>

<Text style={styles.leftSpace}>

a.   Your appointment can be terminated by the Company, without any reason, by giving you not
less than notice months’ prior notice in writing or salary in lieu thereof. For the purpose of
this clause, salary shall mean basic salary.
<View style={styles.space}></View>
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
b.   You may terminate your employment with the Company, without any cause, by giving no less
than  notice months’ prior notice or salary for unsaved period, left after adjustment
of pending leaves, as on date
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
c.   The Company reserves the right to terminate your employment summarily without any notice period or termination payment, if it has reasonable ground to believe you are guilty of
misconduct or negligence, or have committed any fundamental breach of contract or caused any
loss to the Company.
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
d.   On the termination of your employment for whatever reason, you will return to the Company all
property; documents and paper, both original and copies thereof, including any samples,
literature, contracts, records, lists, drawings, blueprints, letters, notes, data and Confidential Information, in your possession or under your control relating to
your employment or to clients’ business affairs.

</Text>






</View>

        </Page>




      {/* page 3 */}
      
      <Page size="A4" style={styles.page }>
      {/* <View style={styles.watermarkContainer}>
      <Image src={backgroundImage} style={styles.watermark} />
      </View>

        <View style={styles.logoContainer}>
          <Image src={backgroundImage} style={styles.logo}></Image>
        </View>

        <View style={styles.footerContainer}> 
        <Text style={styles.boldtext}>SAM HYPER BUSINESS PVT LTD</Text> 
        <Text > <Text style={styles.boldtext} >Reg. Office: </Text>No.76/G1, Ground Floor, Shivalaya Apartments, Rukmani Devi Street</Text> 
        <Text >Majestic Colony, Valsaravakkam, Chennai - 600087.</Text> 
        <Text > <Text style={styles.boldtext}>Branch Office:</Text> No. 22, 1st Floor, Chennai Green City, Thiruverkadu Main Road, Paruthipattu, Chennai - 600071.</Text> 
        <Text ><Text style={styles.boldtext}>Contact:</Text> +91 44 3574 3419 | +91 44 4775 7459 | Email: samhyperbusiness.com</Text> 
        </View>
 */}

<View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>

        {/* <View style={styles.dividerContainer}>
        <View style={styles.divider}></View>
        </View> */}



       <View style={styles.leftSpace}>
       <View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>11. Confidential Information  </Text> 
<View style={styles.space}></View>

<Text style={styles.leftSpace}>

a.  During your employment with the Company you will devote your whole time, attention and skill
to the best of your ability for its business. You shall not, directly or indirectly, engage or associate
yourself with, be connected with, concerned, employed or engaged in any other business or
activities or any other post or work part time or pursue any course of study whatsoever, without
the prior permission of the Company. 

<View style={styles.space}></View>
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
b.   You must always maintain the highest degree of confidentiality and keep as confidential the
records, documents and other Confidential Information relating to the business of the Company
which may be known to you or confided in you by any means and you will use such records,
documents and information only in a duly authorized manner in the interest of the Company. For
the purposes of this clause ‘Confidential Information’ means information about the Company’s
business and that of its customers which is not available to the general public and which may be
learnt by you in the course of your employment. This includes, but is not limited to, information
relating to the organization, its customer lists, employment policies, personnel, and information
about the Company’s products, processes including ideas, concepts, projections, technology,
manuals, drawing, designs, specifications, and all papers, resumes, records and other documents
containing such Confidential Information.

</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
c.   At no time, will you remove any Confidential Information from the office without permission.
</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
d.  Your duty to safeguard and not disclose Confidential Information will survive the expiration or
termination of this Agreement and/or your employment with the Company.

</Text>
<View style={styles.space}></View>

<Text style={styles.leftSpace}> 
e.  Breach of the conditions of this clause will render you liable to summary dismissal under clause
above in addition to any other remedy the Company may have against you in law

</Text>
<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>12. Notices 
 </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>Notices may be given by you to the Company at its registered office address. Notices may be given
by the Company to you at the address intimated by you in the official records. 

</Text>


<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>13. Applicability of Company Policy 
 </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>The Company shall be entitled to make policy declarations from time to time pertaining to matters
like leave entitlement, maternity leave, employees’ benefits, working hours, transfer policies, etc.,
and may alter the same from time to time at its sole discretion. All such policy decisions of the
Company shall be binding on you and shall override this Agreement to that extent. 


</Text>

<View style={styles.bigSpace}></View>

<Text style={styles.boldtext}>14. Governing Law/Jurisdiction
 </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>Your employment with the Company is subject to Indian laws. All disputes shall be subject to the
jurisdiction of High Court Tamil Nadu only. 



</Text>
       </View>
        </Page>




      {/* page 4 */}
      
      <Page size="A4" style={styles.page }>
      {/* <View style={styles.watermarkContainer}>
      <Image src={backgroundImage} style={styles.watermark} />
      </View>

        <View style={styles.logoContainer}>
          <Image src={backgroundImage} style={styles.logo}></Image>
        </View>

        <View style={styles.footerContainer}> 
        <Text style={styles.boldtext}>SAM HYPER BUSINESS PVT LTD</Text> 
        <Text > <Text style={styles.boldtext} >Reg. Office: </Text>No.76/G1, Ground Floor, Shivalaya Apartments, Rukmani Devi Street</Text> 
        <Text >Majestic Colony, Valsaravakkam, Chennai - 600087.</Text> 
        <Text > <Text style={styles.boldtext}>Branch Office:</Text> No. 22, 1st Floor, Chennai Green City, Thiruverkadu Main Road, Paruthipattu, Chennai - 600071.</Text> 
        <Text ><Text style={styles.boldtext}>Contact:</Text> +91 44 3574 3419 | +91 44 4775 7459 | Email: samhyperbusiness.com</Text> 
        </View> */}

<View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
        {/* <View style={styles.dividerContainer}>
        <View style={styles.divider}></View>
        </View> */}
        <View style={styles.leftSpace}> 
        <View style={styles.bigSpace}></View>

<Text style={styles.boldtext}> 15. Acceptance of our offer 

 </Text> 
<View style={styles.space}></View>
<Text style={styles.leftSpace}>Please confirm your acceptance of this Contract of Employment by signing and returning the
duplicate copy. 
</Text>
        </View>

        <View style={styles.bigSpace}></View>
<View style={styles.space}></View>


<View style={styles.centerText}><Text>" We welcome you, and look forward to receiving your acceptance and to working with you. "   
</Text></View>




<View style={styles.bigSpace}></View>
<View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>




        <Text >Your's Faithfully, 
</Text>
<View style={styles.space}></View>

<Text  >SAM HYPER BUSINESS PRIVATE LIMITED</Text>

<View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>



<Text  >( Authorized Signature )</Text>
<View style={styles.space}></View>

<View style={styles.bigSpace}></View>
<View style={styles.bigSpace}></View>


        <View style={styles.rightText}>
<Text >Employee Signature, 
</Text>
<View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>


<Text  >{name}</Text>
<View style={styles.space}></View>

</View>





        </Page>

       
        {/* page 5 */}
      
      <Page size="A4" style={styles.page }>
      {/* <View style={styles.watermarkContainer}>
      <Image src={backgroundImage} style={styles.watermark} />
      </View>

        <View style={styles.logoContainer}>
          <Image src={backgroundImage} style={styles.logo}></Image>
        </View>

        <View style={styles.footerContainer}> 
        <Text style={styles.boldtext}>SAM HYPER BUSINESS PVT LTD</Text> 
        <Text > <Text style={styles.boldtext} >Reg. Office: </Text>No.76/G1, Ground Floor, Shivalaya Apartments, Rukmani Devi Street</Text> 
        <Text >Majestic Colony, Valsaravakkam, Chennai - 600087.</Text> 
        <Text > <Text style={styles.boldtext}>Branch Office:</Text> No. 22, 1st Floor, Chennai Green City, Thiruverkadu Main Road, Paruthipattu, Chennai - 600071.</Text> 
        <Text ><Text style={styles.boldtext}>Contact:</Text> +91 44 3574 3419 | +91 44 4775 7459 | Email: samhyperbusiness.com</Text> 
        </View> */}

<View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>
       <View style={styles.bigSpace}></View>

        {/* <View style={styles.dividerContainer}>
        <View style={styles.divider}></View>
        </View> */}


        <View style={styles.bigSpace}></View>
        <View style={styles.bigSpace}></View>

<View style={[styles.centerText,styles.boldtext]}> <Text>Schedule I - Compensation Details</Text></View>
<View style={styles.bigSpace}></View>
<View style={styles.bigSpace}></View>


<Text style={[styles.boldtext]}>Salary Structure </Text>
<View style={styles.bigSpace}></View>
<View style={styles.bigSpace}></View>

<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>BASIC PAY</Text>
  <Text style={styles.flex}>{basicPay}</Text>
</View>

<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>HOUSE RENT ALLOWANCE</Text>
  <Text style={styles.flex}>{hra}</Text>
</View>
<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>SPECIAL ALLOWANCE</Text>
  <Text style={styles.flex}>{sa}</Text>
</View>
<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>CONVEYANCE</Text>
  <Text style={styles.flex}>{conveyance}</Text>
</View>
<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>BONOUS</Text>
  <Text style={styles.flex}>{bonus}</Text>
</View>
<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>PROFESSIONAL TAX </Text>
  <Text style={styles.flex}>{pt}</Text>
</View>
<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>PROVIDENT FUND</Text>
  <Text style={styles.flex}>{pf}</Text>
</View>
<View style={styles.tablecontainer}>
  <Text style={styles.flex1}>EMPLOYEES' STATE INSURANCE </Text>
  <Text style={styles.flex}>{esi}</Text>
</View>
<View style={styles.bigSpace}></View>
<View style={styles.bigSpace}></View>


<Text>Note: You will receive your salary along with all other benefits included in your remuneration package. This will be provided to you after the deduction of taxes at the source, following the guidelines of relevant laws.</Text>

        </Page>


    </Document>
  );


  const styles = StyleSheet.create({
    tablecontainer:{
      display:"flex",
      flexDirection:"row",
      padding:"10px",
      borderBottom:0.5,
      borderColor: 'grey',
    },
    flex:{
      flex:1,
      textAlign:"right",
      paddingRight:10
    }, flex1:{
      flex:1,
      borderRight:0.5,
      paddingLeft:10  
    },
    testborder:{
      borderWidth:2,
      borderColor: 'black',
    },
    centerText:
    {
      textAlign:"center"

    },
    rightText:
    {
      textAlign:"right"

    },
    leftText:
    {
      textAlign:"left"

    },
    page: {
      paddingTop:"27px",
      paddingBottom:"27px",
      paddingLeft:"50px",
      position:"relative",
      paddingRight:"50px",
      fontFamily: 'Times New Roman',
      fontSize:"12px",
      
    },
    watermarkContainer: {
      
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.15,
    },
    watermark: {
    width:"80%"
    },
    logoContainer:{
      alignItems:"right",
      left:"270px",
      justifyContent: 'right',
    },
    logo:{
      width:"230px",

    },
    footerContainer:{
      display:"flex",
      left:0,
      right:0,  
      borderColor: 'black',
      fontSize:"10px",
      position:"absolute",
      bottom:"37px",
      textAlign:"center",
      justifyContent:"center",
      alignItems:"center"
    },
    boldtext:{
      fontWeight: 'bold',
      fontFamily:"Bold"
    },
    divider:{
      borderBottomWidth: 3,
      borderColor: '#8B0000',
      width:"80%",
    },
    dividerContainer:{
      display:"flex",
      left:0,
      right:0,  
      fontSize:"10px",
      position:"absolute",
      justifyContent:"center",
      alignItems:"center",
      bottom:"25px",
      textAlign:"center",

    },
    space:{
      marginTop:"10px"
    },
     bigSpace:{
      marginTop:"20px"
    },
    leftSpace:
    {
       marginLeft:"15px"
    },
    redText:
    {
      color:"red",
    },
  });


  const sendmail=async()=>{
    try{
      const storageRef = ref(storage, `offerletter/${name}.pdf`);
      let blob = await pdf(MyPDF()).toBlob();
      uploadBytes(storageRef, blob).then((snapshot) => {
        console.log("Uploaded a blob or file!", snapshot);
       });
  
       const pdfRef = ref(storage, `offerletter/${name}.pdf`);
       const downloadURL = await getDownloadURL(pdfRef);
  
      // Send the email with the download link of the PDF
      const serviceID = 'service_1vakeah'; // Replace with your Email.js service ID
      const templateID = 'template_sz6369f'; // Replace with your Email.js template ID
      const userID = 'yuK_tXt2bSt5wElYE'; // Replace with your Email.js user ID
  
      const maildata = {
        "name": name,
        "mail":mail,
        "designation":designation,
        "downloadlink":downloadURL
        // Add the download URL of the PDF to the email template
      };
  
      await emailjs.send(serviceID, templateID, maildata, userID);
      alert("Email Sent Sucessfully (:");
    }
   catch(e)
   {
    alert("Error: please contact 9 840 841 840");
console.log("er:"+e)
   }
  }


  return (
    <div>
      {!authenticated ? (
        // Render the login form if not authenticated
        <div className="login-page-wrap">
          <div className="login-page-content">
            <div className="login-box">
              <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    placeholder="Enter username"
                    className="form-control"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <i className="far fa-envelope" />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <i className="fas fa-lock" />
                </div>
                <div className="form-group">
                  <button type="submit" className="login-btn">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        // Render the content after successful authentication
        <>
          
          <div className="navbar navbar-expand-md header-menu-one bg-light">
      <div className="nav-bar-header-one">
        <div className="header-logo">
          <div>
            <img src="img/dremerz-transparent-logo.png"  width={"180px"} alt="logo" />
          </div>
        </div>
        <div className="toggle-button sidebar-toggle">
          <button type="button" className="item-link">
            <span className="btn-icon-wrap">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
      </div>
      <div className="d-md-none mobile-nav-bar">
         
        <button type="button" className="navbar-toggler sidebar-toggle-mobile">
          <i className="fas fa-bars"></i>
        </button>
      </div>
      <div className="header-main-menu collapse navbar-collapse" id="mobile-navbar">
        <ul  id='navbar-nav'>
        <div id='sam-hyper-business' >Sam Hyper Business Private Limited</div>
        </ul>
        <ul className="navbar-nav">
          <li className="navbar-item dropdown header-admin">
            <div className="navbar-nav-link dropdown-toggle"  data-toggle="dropdown" aria-expanded="false">
              <div className="admin-title">
                <h5 className="item-title">S.H.B</h5>
                <span
                    style={{
                      cursor: "pointer",
                      color: isHovered ? "red" : "black",
                    }}
                    onClick={handleLogout}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    Click to Log Out
                  </span>
              </div>
              <div className="admin-img">
                <img src="img/figure/admin.jpg" alt="Admin" />
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <div className="item-header">
                <h6 className="item-title">Steven Zone</h6>
              </div>
              
            </div>
          </li>
          {/* Add other navbar items here */}
        </ul>
      </div>
    </div>


  <div id="flex-div-pdf">
  <div id="div-one">
  <div className="form-group">
        <label>Employee Name</label>
        <input
          type="text"
          className="form-control"
          name="name"
          value={name}
          onChange={(e)=>{
            setName(e.target.value)
          }}
        />
      </div>
      
      
    
      <div className="form-group">
        <label>Mr/Ms</label>
        <input
          type="text"
          className="form-control"
          value={mrorms}
          onChange={(e)=>{
            setmrorms(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>City</label>
        <input
          type="text"
          className="form-control"
          value={city}
          onChange={(e)=>{
            setCity(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>Address Line 1</label>
        <input
          type="text"
          className="form-control"
          value={addressline1}
          onChange={(e)=>{
            setAddressLine1(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>Address Line 2</label>
        <input
          type="text"
          className="form-control"
          value={addressline2}
          onChange={(e)=>{
            setAddressLine2(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>State</label>
        <input
          type="text"
          className="form-control"
          value={state}
          onChange={(e)=>{
            setstate(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>Pincode</label>
        <input
          type="text"
          className="form-control"
          value={pincode}
          onChange={(e)=>{
            setpincode(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>Employee Designation</label>
        <input
          type="text"
          className="form-control"
          value={designation}
          onChange={(e)=>{
            setDesignation(e.target.value)
          }}
        />
      </div>

      <div className="form-group">
        <label>Company Name</label>
        <input
          type="text"
          className="form-control"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </div>
  <div className="form-group">
        <label>Basic Pay</label>
        <input
          type="text"
          className="form-control"
          name="basicPay"
          value={basicPay}
          onChange={(e)=>{
            setBasicPay(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>PT</label>
        <input
          type="text"
          className="form-control"
          name="pt"
          value={pt}
          onChange={(e)=>{
            setPT(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>HRA</label>
        <input
          type="text"
          className="form-control"
          name="hra"
          value={hra}
          onChange={(e)=>{
            setHRA(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>PF</label>
        <input
          type="text"
          className="form-control"
          name="pf"
          value={pf}
          onChange={(e)=>{
            setPF(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>SA</label>
        <input
          type="text"
          className="form-control"
          name="sa"
          value={sa}
          onChange={(e)=>{
            setSA(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>ESI</label>
        <input
          type="text"
          className="form-control"
          name="esi"
          value={esi}
          onChange={(e)=>{
            setESI(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>Conveyance</label>
        <input
          type="text"
          className="form-control"
          name="conveyance"
          value={conveyance}
          onChange={(e)=>{
            setConveyance(e.target.value)
          }}
        />
      </div>
     
      <div className="form-group">
        <label>Bonus</label>
        <input
          type="text"
          className="form-control"
          name="bonus"
          value={bonus}
          onChange={(e)=>{
            setBonus(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>Date</label>
        <input
          type="date"
          className="form-control"
          name="date"
          value={date}
          onChange={(e)=>{
            setDate(e.target.value)
          }}
        />
      </div>
 
      <div className="form-group">
        <label>Start Date</label>
        <input
          type="date"
          className="form-control"
          name="startDate"
          value={startDate}
          onChange={(e)=>{
            setStartDate(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>Mail ID</label>
        <input
          type="mail"
          className="form-control"
          value={mail}
          onChange={(e)=>{
            setMail(e.target.value)
          }}
        />
      </div>
      <div className="form-group">
        <label>Click to Send Mail</label>
      <div
                    className="button"
                    onClick={()=>{
                      sendmail()
                    }}
                    style={{cursor:"pointer"}}
                  >
                    Click ( :
                  </div>
 
  </div>
  </div>
  <div>
  <PDFViewer  id="div-two">
  <MyPDF></MyPDF>
</PDFViewer>
  </div>


          <div>
          
          </div>
    
  </div>
          
          


        </>
      )}
    </div>
  );
}

export default App;
